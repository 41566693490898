import { Vue, Watch } from "vue-property-decorator";
import GoodShow from "../home/components/GoodShow";
import Label from "../home/components/Label";
import '@/views/home/index.scss'
import { h } from "@vue/runtime-core";
import { RouterView } from "vue-router";
export default class Work extends Vue {
  public screenWidth = 0 // 屏幕宽度
  render(): JSX.Element {
    return this.$route.path === '/work'
     ? <div class="home">
      <Label
        title={{
          zh: '作品展示',
          cn: 'EXHIBITION'
        }}
      />
      {h(GoodShow, {
        screenWidth: this.screenWidth,
        style: {
          paddingLeft: '40px',
          paddingRight: '40px'
        }
      })}
    </div>
    : <RouterView />
  }

  @Watch('$route.path')
  pathChange(val: any): void {
    this.screenWidth = 0
    this.screenWidth = document.body.clientWidth;
  }

  mounted(): void {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
          this.screenWidth = document.body.clientWidth;
      })()
    }
  }
}
