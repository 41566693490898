import { defineComponent, h } from "@vue/runtime-core";

const Label = defineComponent({
  props: {
    title: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  render() {
    const zh = <h2 class="home-label_zh">{ this.title?.zh }</h2>
    const cn = <h2 class="home-label_cn">{ this.title?.cn }</h2>
    return h('div', {
      class: 'home-label'
    }, [zh, cn])
  }
})

export default Label
